@import "../../App.scss";

.navbar-brand img {
  max-height: 50px;
}

.navbar {
  background: transparent;
  position: absolute;
  width: 100%;
  z-index: 1000;
  padding-left: 32px;
  padding-right: 32px;

  .button1 {
    padding: 8px 24px;
  }
}

.nav-link {
  color: rgba($color: #fff, $alpha: 0.65) !important;
  display: flex;
  margin: 0 8px;
  cursor: pointer;

  .line-icon {
    display: block;
  }
  .fill-icon {
    display: none;
  }
}
.nav-link:hover,
.active {
  color: rgba($color: #fff, $alpha: 1) !important;

  .line-icon {
    display: none;
  }
  .fill-icon {
    display: block;
  }
}

.navbar-white,
.mobile-drawer {
  background: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);

  .nav-link {
    color: #909090 !important;
  }

  .nav-link:hover,
  .active {
    color: $secondary !important;
  }

  .button1 {
    // background: #009ee0 !important;
    background: $primary !important;
    box-shadow: 8px 4px 16px rgba(0, 0, 0, 0.25);
    border: none;
    color: #fff !important;
    border-radius: 10px;
    padding: 8px 24px;
  }
}

.navbar-toggler {
  font-size: 28px;
  border: none;
  color: #909090 !important;
}

@media only screen and (max-width: 800px) {
  .navbar {
    padding-left: 0;
    padding-right: 0;
    .navbar-toggler {
      color: #fff !important;
    }
    .navbar-brand img {
      max-height: 50px;
    }
  }
  .navbar-nav {
    margin-left: 0 !important;
  }
  .mobile-drawer {
    background: transparent;
    .button1 {
      width: fit-content;
      margin: 15px auto !important;
    }
    .nav-link {
      margin: 8px 15px;
    }
    img {
      max-height: 60px;
      width: auto;
      margin: 15px auto;
    }
  }
  .navbar-white {
    .nav-link {
      margin: 8px 0;
    }
    .navbar-toggler {
      color: #444544 !important;
    }
    .button1 {
      margin: 8px auto 16px auto !important;
    }
  }
}
