@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "node_modules/react-modal-video/scss/modal-video.scss";

$primary: #1446a0;
$secondary: #85bb40;
$text1: #444544;
$text2: #46597b;

* {
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.05em;
  outline: none;
  scroll-behavior: smooth;
}

.button1 {
  display: flex !important;
  background-color: #fff !important;
  color: $primary !important;
  border-color: #fff !important;
  &:hover {
    box-shadow: 0px 2px 16px #fffe;
  }
}

.heading1 {
  color: transparent;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 64px;
  color: $primary;
  margin: 28px 0;
  letter-spacing: 0.05em;

  span {
    -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: $primary;
    color: transparent;
  }
}

.heading2 {
  color: transparent;
  text-align: left;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 48px;
  color: $secondary;
  letter-spacing: 0.05em;

  span {
    color: $text1;
  }
}

.para1 {
  text-align: left;
  font-size: 24px;
  color: $text2;
}

.contact-button {
  background-color: $primary !important;
  color: #fff !important;
  padding: 8px 42px;
  margin: 16px 0;
  box-shadow: 8px 4px 16px rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 10px;
}

.list1 {
  padding-left: 16px;
  text-transform: capitalize;
}

.desktop {
  display: block;
}
.mobile {
  display: none;
}
@media only screen and (max-width: 800px) {
  .heading1 {
    font-size: 24px !important;
    margin: 18px 0;
    text-align: left;
    padding-left: 15px;

    span {
      -webkit-text-stroke-width: 1px;
    }
  }
  .heading2 {
    font-size: 20px;
  }
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
}
