#rj,
#mp,
#mh,
#tn {
  cursor: pointer;
}

#dot:before {
  content: " ";
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  width: 10px;
  height: 10px;
  background-color: #ff4200;
  border-radius: 50%;
}

#dot:after {
  content: " ";
  position: absolute;
  z-index: 1;
  width: 10px;
  height: 10px;
  background-color: #ff4200;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3) inset;
  animation-name: "ripple";
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-direction: normal;
  -webkit-animation-name: "ripple";
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: ease;
  -webkit-animation-delay: 0s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: normal;
}

@keyframes ripple {
  0% {
    left: 5px;
    top: 5px;
    opcity: 75;
    width: 0;
    height: 0;
    scale: 1;
  }
  100% {
    left: -20px;
    top: -20px;
    opacity: 0;
    width: 50px;
    height: 50px;
    scale: 1.5;
  }
}

.heading4 {
  font-weight: 500;
  font-size: 16px;
  color: #1446a0;
}
.body1 {
  font-weight: 500;
  font-size: 12px;
  color: #000000;
  margin-bottom: 0;

  span {
    font-weight: 500;
    font-size: 32px;
    color: #1446a0;
    line-height: 1.2em;
    text-align: center;
  }
}
.map {
  text-align: center;
  width: 60%;
  margin: auto;
  padding: 36px 16px;
}

@media only screen and (max-width: 800px) {
  .map {
    width: 100%;
    padding: 0;
  }
  .network {
    padding: 0 15px;
    .heading1 {
      padding: 0;
    }
    .heading2 {
      text-align: left !important;
    }
  }
  .body1 {
    font-size: 12px;

    span {
      font-size: 24px;
      line-height: 1.5em;
    }
  }
}
