@import "../../../App.scss";

.features {
  background-image: url(../../../images/features-bg.svg);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 32px 0;
}

.features-row {
  margin: 33px;
  justify-content: center;
  text-align: center;
}

.feature-card {
  display: flex;
  align-items: center;
  border-radius: 10px;
  margin: 12px 0px;
  padding: 16px;
  background-color: #fff;
  box-shadow: -1px -1px 1px rgba(0, 158, 224, 0.13),
    2px 2px 4px rgba(0, 158, 224, 0.13);
  cursor: pointer;

  img {
    max-width: 100px;
    max-height: 100px;
    padding: 8px;
    display: block;
  }

  span {
    display: block;
    text-align: left;
    color: $primary;
    font-weight: 500;
    font-size: 24px;
    padding: 8px;
  }

  &:hover {
    box-shadow: 8px 4px 16px rgba(0, 158, 224, 0.3);
    // 4px 8px 12px rgba(0, 158, 224, 0.13);
  }
}
@media only screen and (max-width: 800px) {
  .features {
    padding: 0 15px;

    .heading1 {
      padding: 0;
    }
    .features-row {
      margin: 0;

      .col-sm-6 {
        padding: 0 4px;
        width: 50%;
      }
    }
  }
  .feature-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: -4px -4px 8px rgba(0, 158, 224, 0.13),
      4px 4px 8px rgba(0, 158, 224, 0.13);
    border-radius: 10px;
    margin: 8px 4px;
    padding: 16px 0;
    background-color: #fff;

    img {
      max-width: 70px;
      max-height: 70px;
      padding: 8px;
      display: block;
    }

    span {
      display: block;
      text-align: center;
      color: $primary;
      font-weight: 500;
      font-size: 16px;
      padding: 8px;
    }
  }
}
