.Landing {
  background-image: url("../../../images/MKS_Home.png");
  background-size: cover;
  background-repeat: no-repeat;

  .container-fluid {
    padding: 100px 48px 24px;
    min-height: 100vh;
  }

  h1 {
    color: transparent;
    text-align: left;
    width: 70vw;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 64px;
    color: #fff;

    span {
      -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: #fff;
      color: transparent;
    }
  }

  .button1 {
    font-size: 18px;
    border-radius: 8px;
    margin: 16px 0 24px 22px;
    padding: 0px 14px;
    vertical-align: middle;
    display: flex;
    align-items: center;
    font-weight: 500;

    i {
      font-size: 36px;
    }
  }
  .slider-button {
    z-index: 100;
    position: absolute;
    font-size: 64px;
    border: none;
    background-color: transparent;
    color: #fff;
    margin: 8px 0;
    padding: 6px 28px;
  }
}

.sub-heading-home {
  display: flex;
  align-content: center;
  align-items: center;
  width: 55vw;
  margin: 12px 0;

  p {
    color: #fff;
    font-size: 24px;
    text-align: left;
    margin-bottom: 0;
  }

  .vertical-line {
    width: 2px;
    height: 100% !important;
    border: 2px;
    padding: 40px 2px;
    background-color: #2297eb;
    margin-right: 20px;
  }
}
.customers {
  padding: 20px 0 24px;
  background: #07141fcc;
  background-blend-mode: multiply;

  p {
    font-size: 24px;
    color: #fff;
  }
  .alice-carousel__prev-btn {
    position: absolute;
    top: 0;
    left: 0;
    text-align: left;
  }
  .alice-carousel__prev-btn-item,
  .alice-carousel__next-btn-item {
    font-size: 48px !important;
    padding: 0px 20px;
    border-radius: 50%;
    transition: 0.4s;

    &:hover {
      color: #fff !important;
      background-color: #0005;
    }
  }
  .alice-carousel__next-btn {
    position: absolute;
    top: 0;
    right: 0;
    text-align: right;
  }
  .alice-carousel__stage-item {
    vertical-align: middle;
    text-align: center;
    padding: 8px 4px;
  }
  .customer-img {
    margin: auto;

    img {
      margin: auto;
      max-height: 60px;
    }
  }
}

@media only screen and (max-width: 800px) {
  .Landing {
    height: auto;
    background-image: url("../../../images/home_protrait.jpg");
    h1 {
      font-size: 34px;
      width: 100%;
    }
    .button1 {
      margin: auto;
      margin-top: 16px;
      margin-left: 0;
      font-size: 18px;
      padding: 6px 32px;

      i {
        font-size: 24px;
      }
    }
    .container-fluid {
      min-height: unset;
      padding: 100px 15px 0;
    }
    .customers {
      margin-top: 24px;

      p {
        font-size: 14px;
      }

      .alice-carousel__prev-btn-item,
      .alice-carousel__next-btn-item {
        font-size: 24px !important;
        padding: 0px 12px;
        border-radius: 50%;
        transition: 0.4s;

        &:hover {
          color: #fff !important;
          background-color: #0005;
        }
      }

      img {
        max-height: 40px;
      }
    }
  }
  .sub-heading-home {
    width: 100% !important;

    p {
      margin-top: 0px;
      font-size: 14px;
      width: 100%;
      display: flex;
      align-items: center;
    }
    .vertical-line {
      padding: 25px 2px;
      background-color: #2297eb;
      margin-right: 10px;
    }
  }
}
