@import "../../../App.scss";
.contact {
  padding: 0;

  .row {
    align-items: center;
    .col-lg-6 {
      padding: -4px !important;
      line-height: 0;
    }
  }

  iframe {
    border: none;
    max-width: 100%;
    max-height: 100%;
    min-height: 500px;
    width: 100%;
    line-height: 0 !important;
  }
}
.contact-form {
  width: 100%;
  padding: 0 48px;
  margin: auto;
  padding-bottom: 16px;

  fieldset {
    border-radius: 8px;
  }
}

input:focus + fieldset {
  border-color: $primary !important;
}

@media only screen and (max-width: 800px) {
  .contact-form {
    padding: 0 15px;
  }
  .contact .heading1 {
    margin-bottom: 0;
  }
}
