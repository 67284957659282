@import "../../../App.scss";

.leadership {
  // padding: 0;

  hr {
    border-color: $primary;
    border-width: 2px;
    margin: 15px;
  }

  .col-lg-6 {
    padding: 0 48px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  p,
  ul {
    padding-top: 12px;
    padding-bottom: 24px;
    font-size: 24px;
    letter-spacing: 0.05em;
    color: $text2;
  }
  .leader-img {
    max-height: 500px;
    display: block;
    margin: auto;
  }
  caption {
    display: block;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 32px;
    letter-spacing: 0.05em;
    color: #8f8f8f;
    text-align: center;
    margin: auto;
  }
  .heading2 {
    text-transform: capitalize;
    line-height: 1.3em;
  }
  .details {
    padding: 0 48px;
  }
  .leader-section {
    padding-left: 8px;
    .heading2 {
      font-size: 56px;
    }
  }
}
@media only screen and (max-width: 800px) {
  .leadership {
    padding: 0 15px;
    .col-lg-6 {
      padding: 0 !important;
    }
    hr {
      margin: 15px 0;
    }
    .heading1 {
      padding-left: 0;
    }
    p,
    ul {
      padding-top: 8px;
      padding-bottom: 0;
      font-size: 14px;
    }
    img {
      max-height: 400px;
    }
    caption {
      font-size: 20px;
    }
  }
  .details {
    padding: 0 !important;
  }
  .leader-section {
    padding-left: 0 !important;
    .heading2 {
      font-size: 20px !important;
    }
  }
}
