@import "../../../App.scss";

.about-us-landing {
  padding: 0;
  padding-left: 36px;

  .col-lg-6:first-child {
    padding: 0 48px;
  }

  ul {
    color: $text2;
    font-size: 24px;
    letter-spacing: 0.05em;
  }

  img {
    border-radius: 24px 0 0;
    padding: 48px 0 24px 64px;
  }
}
@media only screen and (max-width: 800px) {
  .about-us-landing {
    padding: 0 15px;

    .heading1 {
      padding-left: 0;
    }

    .row {
      flex-direction: column-reverse;
    }

    .col-lg-6:first-child {
      padding: 0 15px;
    }

    ul {
      font-size: 14px;
    }

    img {
      border-radius: 0;
      padding: 0;
      margin-bottom: 16px;
    }
  }
}
