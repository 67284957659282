.certificates {
  padding: 1px 0 32px;
  box-shadow: inset 2px 0px 4px rgba(0, 0, 0, 0.25);
  display: none;
}
@media only screen and (max-width: 800px) {
  .certificates {
    padding: 0;
    .row {
      padding: 0 10px;
    }
    .heading1 {
      padding-top: 15px;
      padding-left: 0;
    }
    .col-sm-6 {
      width: 50%;
      padding: 8px;
    }
  }
}
