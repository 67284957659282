@import "../../../App.scss";

.products {
  padding: 0;

  .col-lg-6:first-child {
    padding: 0 48px 48px;
  }

  .heading2 {
    padding-top: 48px;
  }

  .sub-heading1 {
    color: #444544;
    font-weight: 500;
    font-size: 24px;
    cursor: pointer;
    position: relative;
    text-decoration: none;
    width: fit-content;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: #444544;
      visibility: hidden;
      transform: scaleX(0);
      transition: all 0.3s ease-in-out 0s;
    }
    &:hover::before {
      visibility: visible;
      transform: scaleX(1);
    }
  }

  .alice-carousel__stage-item {
    vertical-align: middle;
    padding: 24px;
    // transition: 0.01s ease-in-out;

    img {
      transition: max-height 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      border-radius: 24px;
      width: 100%;
      height: 100%;
      border: 1px solid #ddd;
      max-height: 70vh;
    }
  }
  .__active {
    padding: 0;

    img {
      box-shadow: 4px 8px 12px rgba(0, 0, 0, 0.25);
      padding: 0;
      margin: 16px 0;
      max-height: 80vh;
    }
  }

  .product-details {
    padding: 0 48px;

    ul {
      color: $text2;
      font-size: 24px;
      letter-spacing: 0.05em;
    }
  }
}
.custom-slider-list {
  list-style: none;
  margin: 48px auto 16px;
  width: fit-content;
  padding-left: 0;

  li {
    padding: 6px;
    display: inline-block;
    margin: 4px;
    border-radius: 50%;
    background-color: #c4c4c4;
    cursor: pointer;
  }

  .active {
    background-color: $secondary;
  }
}

.custom-slider-arrow {
  position: absolute;
  margin-top: 0%;
  text-align: center;
  border-top-right-radius: 22px;
  padding: 0 4px;
  box-shadow: 8px 4px 8px rgba(0, 0, 0, 0.25);
  background: #fff;

  i {
    font-size: 56px;
    display: block;
    color: $primary;
    cursor: pointer;
  }

  span {
    font-size: 56px;
    display: block;
    line-height: 0;
    margin: 8px 0 4px 0;
    font-weight: 700;
    color: $secondary;
  }
}

// .cor-img {
//   width: 100%;
//   height: 100%;
//   border: 1px solid red;
// }
@media only screen and (max-width: 800px) {
  .products {
    .row {
      flex-direction: column-reverse;
      align-items: flex-start;

      .col-lg-6:first-child {
        padding: 0 15px 0px;
      }
    }
    .heading1 {
      margin-bottom: 0;
    }
    .heading2 {
      padding-top: 12px;
    }
    p,
    .sub-heading1 {
      font-size: 14px;
      margin-bottom: 4px;
    }
    .product-details {
      padding: 0px;
      .heading1 {
        font-size: 18px !important;
      }

      ul {
        font-size: 14px;
      }
      .sub-heading1 {
        padding-left: 15px;
      }
    }
  }

  .custom-slider-list {
    margin: 0 auto;
    
  }
  .slider-content {
    height: 280px;
  }

  .custom-slider-arrow {
    position: static;
    width: fit-content;
    margin: auto !important;
    margin-top: 10px;
    border-radius: 16px;
    padding: 0 16px;
    box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.25);
    display: none;
    align-items: center;
    flex-direction: row-reverse;

    i {
      font-size: 24px;
      display: inline-block;
    }

    span {
      margin: 0 8px;
      font-size: 20px;
      display: inline-block;
    }

    ul {
      font-size: 16px;
    }
  }

  
}
