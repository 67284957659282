.footer {
  background-color: #009ee0;
  .container-fluid {
    padding: 16px 48px;
  }

  .heading3 {
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  p {
    font-size: 16px;
    color: #fff !important;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin-left: 16px;
    margin-bottom: 0;

    i {
      font-size: 24px;
    }
    span {
      margin-left: 8px;
      word-break: break-all;
    }
  }

  ul {
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    margin-bottom: 0;

    a {
      color: #fff;
    }
  }
}

.copyright {
  color: #fff;
  background-color: #007caf;
  padding: 12px;
  font-weight: 500;
  display: block;
  font-size: 12px !important;
  text-align: center !important;
  width: 100% !important;
}

@media only screen and (max-width: 800px) {
  .footer {
    .container-fluid {
      padding: 16px 15px;
    }
    .heading3 {
      font-size: 14px;
    }
    p,
    li {
      font-size: 12px !important;
    }
    .col-lg-8 {
      margin-bottom: 15px;
    }
  }
}
