@import "../../../App.scss";

.gallery {
  text-align: center;
  // background: #d9f0ff;
  margin: 0 !important;
  overflow-x: hidden;
  padding-bottom: 40px;

  .gallery-container {
    max-height: 80vh !important;
    overflow-y: auto;
    margin: 0 5%;
    overflow-x: hidden;
  }
  /* width */
  ::-webkit-scrollbar {
    width: 20px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
    background-color: #fff;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $secondary;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    box-shadow: inset 0 0 5px grey;
  }

  form {
    display: flex;
    justify-content: center;
    margin: 30px 0 40px 0;
  }

  input {
    display: none;
  }

  label {
    background: $secondary;
    color: #fff;
    font-weight: 500;
    padding: 5px 12px;
    border-radius: 10px;
    transition: 0.3s;
    cursor: pointer;
  }

  input:checked ~ label {
    background: $primary;
    box-shadow: 5px 2px 15px #a8b4fc80;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    padding: 0;
  }

  li {
    margin: 10px 10px;
  }

  figure {
    z-index: 1;
    width: 300px;
    height: 250px;
    overflow: hidden;
    margin: 0 5px;
    animation: show 0.8s ease;
  }

  @keyframes show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  img {
    width: 300px;
    height: 250px;
    left: 0;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: 0 10px 15px #a8b4fc30;
  }

  figure:hover figcaption {
    transform: scale(1);
    opacity: 1;
  }

  figcaption {
    margin: 0;
    opacity: 0.6;
    // position: absolute;
    transform: scale(0.8);
    margin-top: -70px !important;
    margin-bottom: 20px !important;
    background: #000a;
    border-radius: 6px;
    font-size: 16px;
    color: #fff;
    margin: 0 24px;
    left: 0;
    right: 0;
    padding: 6px;
    transition: 0.3s;
  }

  #root a {
    color: #faa7b7;
  }

  .photo-source {
    // font-family: "Open Sans", sans-serif;
    // font-size: 18px;
    font-weight: 300;
    color: #969696;
    margin-bottom: 20px;
  }

  @keyframes transform {
    0%,
    100% {
      border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
    }
    14% {
      border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
    }
    28% {
      border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
    }
    42% {
      border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
    }
    56% {
      border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
    }
    70% {
      border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
    }
    84% {
      border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
    }
  }

  @keyframes movement {
    0%,
    100% {
      transform: none;
    }
    50% {
      transform: translateY(20%) rotateY(10deg);
    }
  }
}

@media only screen and (max-width: 800px) {
  .gallery-container {
    max-height: 50vh !important;
  }
}
